import React from 'react';

const SlackRedirectPage = () => {
  React.useEffect(() => {
    window.location.href = process.env.GATSBY_SLACK_INVITE_URL as string;
  }, []);
  return <div />;
};

export default SlackRedirectPage;
